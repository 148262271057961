import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Product from '../component/Product';
import useBasket from '../hooks/useBasket';
import Seo from '../component/seo';

export default function Dish({ location }) {
  const {
    delivery,
    totalPrice,
    amount,
    setNewDelivery,
    setNewAmount,
    setNewTotalPrice,
  } = useBasket();
  const [, category, , id] = location.pathname.split('/');
  const {
    allStrapiMetaPage: { nodes: metaPage },
    allStrapiDish: { nodes },
    allStrapiTypeLink: { nodes: links },
  } = useStaticQuery(
    graphql`
      query DishQuery {
        allStrapiMetaPage {
          nodes {
            header
            description
            page_name
          }
        }
        allStrapiTypeLink {
          nodes {
            from
            to
          }
        }
        allStrapiDish {
          nodes {
            id
            posterId
            header
            animationPhoto {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 5)
                }
              }
            }
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            price
            type
            description {
              data {
                description
              }
            }
            options {
              id
              posterId
              price
              food_option
            }
            isPremium
            isNew
            weight
          }
        }
      }
    `,
  );
  const dish = nodes.find(
    ({ id: idDish, type }) => type === category && id === idDish,
  );

  const { from, to } = links.find((link) => link.from === category);
  const adviceType = nodes.filter(({ type }) => type === to);
  const values = [...Array(adviceType.length)].map((_, i) => i);
  const random = [...Array(4)].map(
    () => values.splice(Math.floor(Math.random() * values.length), 1)[0],
  );
  const randomAdvice = useMemo(() => random.map((r) => adviceType[r]), [nodes]);
  const { header, description } = metaPage.find(
    (node) => node.page_name === from,
  );

  return (
    <>
      <Seo title={header} description={description} />
      <Product
        delivery={delivery}
        totalPrice={totalPrice}
        amountGlobal={amount}
        setNewDelivery={setNewDelivery}
        dish={dish}
        setNewAmount={setNewAmount}
        setNewTotalPrice={setNewTotalPrice}
        urlBack={from}
        randomAdvice={randomAdvice}
      />
    </>
  );
}

Dish.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
};
