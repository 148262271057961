import * as React from 'react';
import { useState } from 'react';
import { Link } from 'gatsby';
import { SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';
import Container from '../Container';
import Button from '../Button';
import Minus from '../../assets/icons/minus.svg';
import Plus from '../../assets/icons/plus.svg';
import {
  Back,
  BtnBack,
  Wrapper,
  WrapperImages,
  NodeImages,
  SwitchImage,
  Image,
  Content,
  Title,
  Price,
  SelectOption,
  TitleIngredients,
  WrapperAmount,
  Amount,
  ButtonAmount,
  Input,
  Desc,
  Weight,
  WrapperAdvice,
  WishHeader,
  Advice,
  SwiperAdvice,
} from './styled';
import CardDish from '../CardDish';

export default function Product({
  dish,
  urlBack,
  delivery,
  setNewDelivery,
  amountGlobal,
  setNewAmount,
  totalPrice,
  setNewTotalPrice,
  randomAdvice,
}) {
  const [amount, setAmount] = useState(1);
  const [price, setPrice] = useState(0);
  const {
    posterId,
    header,
    photo,
    price: defaultPrice,
    description,
    options,
    weight,
  } = dish;
  const [activeOption, setActiveOption] = useState(
    options[0]?.food_option || null,
  );
  const [activePoster, setActivePoster] = useState(
    posterId || options[0]?.posterId || null,
  );
  const { localFile } = Array.isArray(photo) ? photo[0] : photo;
  const {
    data: { description: descriptionDish },
  } = description;
  const [currentImage, setCurrentImage] = useState(localFile);
  const onClickImage = (src) => setCurrentImage(src);

  const switchImages = Array.isArray(photo) ? (
    photo.map(({ localFile: img }) => (
      <SwitchImage onClick={() => onClickImage(img)} Tag="div" />
    ))
  ) : (
    <SwitchImage
      onClick={() => onClickImage(localFile)}
      image={getImage(localFile)}
      alt={header}
    />
  );

  const onChangeSelect = ({ target: { value } }) => {
    const [priceOption, option, activeId] = value.split('/');

    setActiveOption(option);
    setActivePoster(activeId);
    setPrice(+priceOption);
  };

  const dropDownOptions = !defaultPrice && (
    <SelectOption onChange={(event) => onChangeSelect(event)}>
      {options.map(
        ({ posterId: activeId, price: priceOption, food_option: option }) => (
          <option key={activeId} value={`${priceOption}/${option}/${activeId}`}>
            {option}
          </option>
        ),
      )}
    </SelectOption>
  );

  const getPrice = () => {
    if (!defaultPrice && price === 0) {
      return options[0].price;
    }

    if (price > 0) {
      return price;
    }

    return defaultPrice;
  };

  const onDelivery = () => {
    let match = false;
    const copyDish = { ...dish };
    const resDelivery = delivery.map((item) => {
      if (!item.activeOption) {
        item.activeOption = null;
        copyDish.optionPrice = null;
      }

      if (!item.posterId) {
        item.posterId = activePoster;
      }

      if (item.id === copyDish.id && item.activeOption === activeOption) {
        item.amount += +amount;
        match = true;

        return item;
      }

      return item;
    });

    if (!match) {
      copyDish.amount = +amount;
      copyDish.activeOption = activeOption;
      copyDish.posterId = activePoster;
      copyDish.optionPrice = getPrice();
      resDelivery.push(copyDish);
    }

    setAmount(1);
    setNewDelivery(() => [...resDelivery]);
    setNewTotalPrice(() => +totalPrice + +getPrice() * +amount);
    setNewAmount(() => +amountGlobal + +amount);
  };

  return (
    <Container>
      <Back>
        <Link to={`/${urlBack}`}>
          <span>←</span>
          <BtnBack>Всі товари</BtnBack>
        </Link>
      </Back>
      <Wrapper>
        <WrapperImages>
          <NodeImages>{switchImages}</NodeImages>
          <Image image={getImage(currentImage)} alt={header} />
        </WrapperImages>
        <Content>
          <Title>{header}</Title>
          <Price>{getPrice()} грн.</Price>
          {!defaultPrice && (
            <TitleIngredients>Інгрідієнт на вибір:</TitleIngredients>
          )}
          {dropDownOptions}
          <WrapperAmount>
            <Amount>
              <ButtonAmount
                type="button"
                onClick={() => setAmount(+amount - 1)}
                disabled={amount < 2}
              >
                <Minus />
              </ButtonAmount>
              <Input
                name="amountDish"
                value={amount}
                onChange={({ target }) =>
                  setAmount(target.value === '0' ? 1 : target.value)
                }
                onBlur={({ target }) =>
                  setAmount(target.value === '' ? 1 : target.value)
                }
                maxLength={3}
              />
              <ButtonAmount
                type="button"
                onClick={() => setAmount(+amount + 1)}
                disabled={amount > 998}
              >
                <Plus />
              </ButtonAmount>
            </Amount>
            <Button variant="primary" type="button" onClick={onDelivery}>
              Додати до корзини
            </Button>
          </WrapperAmount>
          <Desc>
            {descriptionDish}
            <Weight>{weight}</Weight>
          </Desc>
        </Content>
      </Wrapper>
      <WrapperAdvice>
        <WishHeader>Радимо спробувати</WishHeader>
        <Advice>
          {randomAdvice.map((item) => (
            <CardDish
              delivery={delivery}
              setDishes={setNewDelivery}
              dish={item}
              totalPrice={totalPrice}
              amountDish={amountGlobal}
              setNewTotalPrice={setNewTotalPrice}
              setAmountDish={setNewAmount}
              animation={false}
            />
          ))}
        </Advice>
        <SwiperAdvice slidesPerView="auto" spaceBetween={50}>
          {randomAdvice.map((item) => (
            <SwiperSlide key={item.id}>
              <CardDish
                delivery={delivery}
                setDishes={setNewDelivery}
                dish={item}
                totalPrice={totalPrice}
                amountDish={amountGlobal}
                setNewTotalPrice={setNewTotalPrice}
                setAmountDish={setNewAmount}
              />
            </SwiperSlide>
          ))}
        </SwiperAdvice>
      </WrapperAdvice>
    </Container>
  );
}

Product.propTypes = {
  dish: PropTypes.shape({
    posterId: PropTypes.string,
    header: PropTypes.string.isRequired,
    photo: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
    description: PropTypes.shape({
      data: PropTypes.shape({ description: PropTypes.string.isRequired }),
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        posterId: PropTypes.string,
        price: PropTypes.number.isRequired,
        food_option: PropTypes.string.isRequired,
      }),
    ),
    isPremium: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    weight: PropTypes.string.isRequired,
  }).isRequired,
  urlBack: PropTypes.string.isRequired,
  delivery: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  setNewDelivery: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  setNewTotalPrice: PropTypes.func.isRequired,
  amountGlobal: PropTypes.number.isRequired,
  setNewAmount: PropTypes.func.isRequired,
  randomAdvice: PropTypes.object.isRequired,
};
