import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 40px 20px;
  background: #f5f5f5;
  width: 100%;

  @media (max-width: 1200px) {
    padding: 30px 20px;
  }

  @media (max-width: 960px) {
    max-width: 650px;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    align-items: start;
  }
`;

export const WrapperImages = styled.div`
  display: flex;
  align-items: start;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
  }
`;

export const NodeImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  margin-right: 10px;
  max-height: 735px;

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 10px 0 10px 0;
    order: 2;
  }
`;

export const SwitchImage = styled(GatsbyImage)`
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
  font-size: 14px;
`;

export const BtnBack = styled.button`
  margin-left: 5px;
  font-family: 'Josefin Sans', sans-serif;
`;

export const Image = styled(GatsbyImage)`
  width: 490px;
  height: 735px;
  filter: saturate(1.2);

  @media (max-width: 1200px) {
    width: 370px;
    height: 555px;
    margin-bottom: 20px;
  }

  @media (max-width: 960px) {
    width: 510px;
    height: 850px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 450px;
  }
`;

export const Content = styled.div`
  margin-left: 40px;

  @media (max-width: 960px) {
    margin: unset;
  }
`;

export const Title = styled.h1`
  margin-bottom: 18px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.35;
  font-size: 24px;

  @media (max-width: 1200px) {
    font-size: 22px;
  }

  @media (max-width: 960px) {
    font-size: 20px;
  }
`;

export const Price = styled.div`
  color: #000;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.35;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (max-width: 960px) {
    font-size: 16px;
  }
`;

export const SelectOption = styled.select`
  min-width: 150px;
  border: 1px solid #ddd;
  background: #f8f8f8;
  color: rgba(0, 0, 0, 0.4);
  padding: 2px 30px 2px 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.55;
  font-weight: 300;
  margin-bottom: 20px;
  outline: none;
`;

export const TitleIngredients = styled.div`
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 300;
  text-align: left;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

export const WrapperAmount = styled.div`
  display: flex;
  margin: 20px 0 30px 0;

  @media (max-width: 576px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const Amount = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  border: 1px solid #777;
  font-size: 14px;
  border-radius: 30px;
  margin-right: 10px;

  @media (max-width: 576px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Input = styled.input`
  width: 30px;
  height: 30px;
  text-align: center;
  background: none;
`;

export const ButtonAmount = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Desc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 1.55;
  margin-top: 20px;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

export const Weight = styled.span``;

export const WrapperAdvice = styled.div`
  background: #f5f5f5;
  padding: 30px 20px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 960px) {
    max-width: 650px;
    padding: 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const WishHeader = styled.h1`
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  padding: 70px 20px 40px 20px;
  font-size: 22px;

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: 960px) {
    font-size: 18px;
  }
`;

export const Advice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  & > div {
    width: 260px;

    @media (max-width: 1200px) {
      width: 220px;
    }

    a > .cardImage {
      height: 290px;

      @media (max-width: 1200px) {
        height: 240px;
      }
    }
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

export const SwiperAdvice = styled(Swiper)`
  display: none;
  width: 100%;
  background: #f5f5f5;
  max-width: 650px;

  .swiper-slide {
    width: 260px;

    & > div {
      @media (max-width: 1200px) {
        width: 240px;
      }

      @media (max-width: 768px) {
        width: 240px;
      }

      @media (max-width: 320px) {
        width: 240px;
      }
    }

    @media (max-width: 768px) {
      width: 240px;
    }

    @media (max-width: 320px) {
      width: 240px;
    }

    a > .cardImage {
      height: 290px;

      @media (max-width: 1200px) {
        height: 240px;
      }
    }

    @media (max-width: 768px) {
      width: 240px;
    }

    @media (max-width: 320px) {
      width: 240px;
    }
  }

  @media (max-width: 960px) {
    display: block;
  }
`;
